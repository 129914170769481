import slugify from 'slugify';

import router from '@/router';


export function user (state) {
  return state.user;
}

export function isAuthenticated (state) {
  return state.isAuthenticated;
}

export function groups (state) {
  return state.groups;
}

export function groupById (state) {
  return (id) => {
    for (let i = 0; i < state.groups.length; i++) {
      if (state.groups[i].id == id) {
        return state.groups[i];
      }
    }
    return null;
  }
}

export function apiKeys (state) {
  return state.apiKeys;
}

export function apiKeyScopes (state) {
  return state.apiKeyScopes;
}

export function apiKeyScopeById (state) {
  return (id) => {
    for (const property in state.apiKeyScopes) {
      let scope = state.apiKeyScopes[property];
      if (scope.id == id) {
        return scope;
      }
    }
  }
}

export function octoprintFilesUploadUrl (state, getters) {
  let serverUrl = new URL(process.env.VUE_APP_API_PATH_PRIVATE, process.env.VUE_APP_API_BASE_URL).href
  return serverUrl + 'groups/' + getters.currentGroupId + '/';
}

export function currentGroupId () {
  return localStorage.getItem('currentGroupId');
}

export function currentGroup (state, getters) {
  return getters.groupById(getters.currentGroupId);
}

export function groupPublicURLByGroupId (state, getters, rootState, rootGetters) {
  return (groupId) => {
    let group = rootGetters['account/groupById'](groupId);
    return process.env.VUE_APP_KARMEN_WEBSITE_GROUPS_URL + slugify(group.name + ' ' + group.id, {lower: true, strict: true});
  }
}

export function currentGroupIdOrLogout () {
  let id = currentGroupId();
  if (id == null || id == undefined || id == 'undefined' || id == '') {
    router.push({ name: 'Logout' });
    return null;
  }
  return id;
}

export function accessToken () {
  return localStorage.getItem('accessToken');
}

export function refreshToken () {
  return localStorage.getItem('refreshToken');
}

export function hasPermission (state, getters) {
  return (permission, groupId) => {
    let group = null;
    if (groupId && groupId != '' && groupId.length > 0) {
      group = getters.groupById(groupId);
    } else {
      group = getters.currentGroup;
    }
    if (group) {
      return group.me?.permissions?.indexOf(permission) >= 0;
    }
    return false;
  }
}

export function discourseRegistered(state) {
  return state.discourseRegistered;
}
