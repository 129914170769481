import { i18n } from '@/i18n.js';

export default function () {
  return {
    // accessToken: null, -> accessToken is stored in localStorage
    // refreshToken: null, -> refreshToken is stored in localStorage
    user: {},
    isAuthenticated: false,
    groups: [],
    // currentGroupId: null, -> currentGroupId is stored in localStorage
    apiKeys: [],
    discourseRegistered: null,
    apiKeyScopes: {
      PRINT_TO_GROUP: {
        id: 'PRINT_TO_GROUP',
        title: i18n.global.t('views.settings.apiTokenAdvancedTitle_PRINT_TO_GROUP'),
        description: i18n.global.t('views.settings.apiTokenAdvancedDesc_PRINT_TO_GROUP')
      },
      FULL_READ: {
        id: 'FULL_READ',
        title: i18n.global.t('views.settings.apiTokenAdvancedTitle_FULL_READ'),
        description: i18n.global.t('views.settings.apiTokenAdvancedDesc_FULL_READ')
      },
      LIMITED_WRITE: {
        id: 'LIMITED_WRITE',
        title: i18n.global.t('views.settings.apiTokenAdvancedTitle_LIMITED_WRITE'),
        description: i18n.global.t('views.settings.apiTokenAdvancedDesc_LIMITED_WRITE')
      }
    }
  };
}
