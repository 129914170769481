import httpClient from '@/httpClient';
import { throwNetworkUnhandledError } from '@/errors';

export async function loadDefaultData({ dispatch }) {
  return Promise.all(
    [dispatch('me'),
    dispatch('groups')]
  );
}

export async function hasPermission({ commit, getters, dispatch }) {
  if (!getters.isAuthenticated) {
    let refreshToken = getters.refreshToken;
    if (refreshToken) {
      let url = '/tokens/refresh/';
      return httpClient.post(url, { refresh: refreshToken }).then(function (response) {
        commit('setAccessToken', response?.data?.access);
        return dispatch('loadDefaultData').then(() => {
          return response;
        });
      }).catch((error) => {
        return throwNetworkUnhandledError(error);
      });
    }
    throw Error('hasPermission: refreshToken does not exists!');
  }
  return true;
}

export function login({ commit, dispatch }, payload) {
  let url = '/tokens/';
  return httpClient.post(url, { username: payload.username.toLowerCase(), password: payload.password }).then(function (response) {
    commit('setRefreshToken', response?.data?.refresh);
    commit('setAccessToken', response?.data?.access);
    return dispatch('loadDefaultData').then(() => {
      return response;
    });
  }).catch((error) => {
    throwNetworkUnhandledError(error);
    return Promise.reject(error)
  });
}

export function me({ commit, dispatch }) {
  let url = '/users/me/';
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setUser', response?.data);

    // when user is loaded, check Discourse registration
    dispatch('loadUserDiscourseRegistration').catch((error) => {
      return throwNetworkUnhandledError(error);
    });
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function groups({ commit }) {
  let url = '/users/me/groups/';
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setGroups', response?.data);
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function updateGroup(commit, payload) {
  let url = '/users/me/groups/' + payload.id + '/';
  let obj = {
    name: payload.name,
    is_public: payload.is_public,
    public_description: payload.public_description,
    public_website_url: payload.public_website_url,
    settings: {
      print_queue_enabled: payload?.settings?.print_queue_enabled
    }
  };
  return httpClient.patch(url, obj, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function updateGroupAvatar(commit, payload) {
  let url = '/users/me/groups/' + payload.groupId + '/';
  let formData = new FormData();
  formData.append('avatar', payload.imgData);
  return httpClient.patch(url, formData, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function downloadAvatarAsObjectURL(commit, payload) {
  let url = '/groups/' + payload.groupId + '/avatar?filename=' + payload.filename;
  return httpClient.get(url, { authorizeRequest: true, responseType: 'blob' }).then((res) => {
    if (res?.data) {
      return URL.createObjectURL(res.data);
    }
    return;
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function createGroup(commit, payload) {
  let url = '/users/me/groups/';
  return httpClient.post(url, { name: payload.name }, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function changeUserRoleInGroup(commit, payload) {
  let url = '/groups/' + payload.groupId + '/users/' + payload.userId + '/';
  return httpClient.patch(url, { role: payload.role }, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function deleteUserFromGroup(commit, payload) {
  let url = '/groups/' + payload.groupId + '/users/' + payload.userId + '/';
  return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function inviteUserToGroup(commit, payload) {
  let url = '/groups/' + payload.groupId + '/users/';
  return httpClient.post(url, { role: 'observer', username: payload.email.toLowerCase() }, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function apiKeys({ commit }) {
  let url = '/users/me/api_keys/';
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setApiKeys', response?.data);
  }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function requestNewDeviceKey() {
  let url = '/device_keys/';
  return httpClient.post(url, {}, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function createApiKey( commit, payload ) {
  let url = '/users/me/api_keys/';
  return httpClient.post(url, { name: payload.name, scope: payload.scope }, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function deleteApiKey(commit, payload) {
  let url = '/users/me/api_keys/' + payload.id + '/';
  return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function logout({ commit }) {
  let url = '/tokens/mine/';
  commit('setUser', null);
  commit('setCurrentGroupId', null);
  commit('setAccessToken', null);
  commit('setRefreshToken', null);

  // Local storage may be used by some components to store component state related to user session.
  // Let's clean up everything on logout.
  // clean-up localstorage except those entries explicitly named here
  let entries = Object.entries(localStorage);
  for (let e of entries) {
    if (!['locale', 'theme'].includes(e[0])) {
      localStorage.removeItem(e[0]);
    }
  }

  return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
    if (error?.response?.status != 401) { // 401 == forbidden, user already logged out or token expired
      return throwNetworkUnhandledError(error);
    }
  });
}

export function sendInvitation(commit, payload) {
  let url = '/invitations/';
  return httpClient.post(url, { email: payload.email.toLowerCase() }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function confirmUserPassword(commit, payload) {
  let url = '/users/';
  return httpClient.post(url, { password: payload.password, token: payload.token }).catch((error) => {
    if (error?.response?.status == 401) {
      throw error;
    }
    return throwNetworkUnhandledError(error);
  });
}

export function passwordResetRequest(commit, payload) {
  let url = '/users/password-reset/request/';
  return httpClient.post(url, { email: payload.email.toLowerCase() }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function passwordResetConfirm(commit, payload) {
  let url = '/users/password-reset/confirm/';
  return httpClient.post(url, { token: payload.token, password: payload.password }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function setTelegramAccount(commit, telegram_data) {
  let url = '/telegram/register/';
  let obj = {
    telegram_data: telegram_data
  };
  return httpClient.post(url, obj, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function deleteTelegramAccount() {
  let url = '/telegram/register/';
  return httpClient.delete(url, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function userInGroupNotificationSettings(commit, groupId) {
  let url = '/notifications/settings/?group_id=' + groupId;
  return httpClient.get(url, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function updateUserInGroupNotificationSettings(commit, payload) {
  let url = '/notifications/settings/' + payload.id + '/';
  let data = {};
  if (payload.telegram) {
    data.telegram = payload.telegram;
  }
  if (payload.email) {
    data.email = payload.email;
  }
  return httpClient.patch(url, data, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function sendTestNotification(commit, notificationsSettingsId) {
  let url = '/notifications/settings/' + notificationsSettingsId + '/send-test-notification/';
  return httpClient.post(url, null, { authorizeRequest: true }).catch((error) => {
    return throwNetworkUnhandledError(error);
  });
}

export function loadUserDiscourseRegistration({ commit }) {
  let url = 'discourse-sso/verify';
  return httpClient.get(url, { authorizeRequest: true }).then(function (response) {
    commit('setDiscourseRegistered', response?.data?.registered);
  }).catch(() => {
    // hide any error when detecting Discourse;
    // when there is error, discourse functionaly won't be enabled anyway
  });
}
